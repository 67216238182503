import "./Content.css";

export function Content() {
  return (
    <div className='content'>
        <img src="/mjp-comic.png" alt="MJP logo" />
        This site is under construction
    </div>
  );
}
